import {createContext, useEffect, useReducer} from "react";
import {authReducer} from "../state_management/reducers/authReducer";
import {Auth} from "aws-amplify";
import {actionCreators} from "../state_management/actions/authActions";


interface IUser {
    userName: string,
    email: string,
    company: string
}

interface IDispatchAuthAction {
    loading: boolean,
    error: string,
    user: IUser | null | undefined
}

interface IAuthState {
    state: string,
    loading: boolean,
    error: string,
    user: IUser | null | undefined
}

interface IContextAuthState {

    authState: IAuthState,
    dispatch: (action : IAuthAction) => void,
    signOut: () => void

}

interface IAuthPayload {
    loading: boolean,
    error: string,
    user?: IUser | null | undefined
}

interface IAuthAction{

    type: string
    payload: IAuthPayload

}

interface IContextProps {

    children: JSX.Element;
}

let initialAuthState: IContextAuthState = {

    dispatch: (action: IAuthAction) => {},
    signOut: () => {

    },
    authState: {state: 'signedIn', loading: false, error: '', user: null}
};

export const AuthContext = createContext<IContextAuthState>(initialAuthState);


export function AuthProvider(props: IContextProps) {


        const userName: string = localStorage['userName']
        const email: string = localStorage['email']
        const company: string = localStorage['company']
        initialAuthState = {
            ...initialAuthState, authState: {
                state: 'signedIn',
                loading: false,
                error: '',
                user: {
                    userName: userName,
                    email: email,
                    company: company
                }
            }
        }


    const [authState, dispatch] = useReducer(authReducer, {state: 'signedIn', loading: false, error: '', user: null})

    useEffect(() => {

        loggedInUser()
    }, []);

    async function loggedInUser() {

        try {
            let user = await Auth.currentAuthenticatedUser()
            localStorage['authentication'] = true
            localStorage['userName'] = user.username
            localStorage['email'] = user.attributes.email
            localStorage['company'] = user.attributes['custom:company']
            dispatch(actionCreators.signedIn(false, '', {
                userName: user.username,
                email: user.attributes.email,
                company: user.attributes['custom:company']
            }))

        } catch (err) {
            dispatch(actionCreators.signIn(false, ''))
        }
    }

    async function signOut() {

        try {
            dispatch(actionCreators.signOut(false, ''))
            localStorage.removeItem('authentication');
            localStorage.removeItem('userName');
            localStorage.removeItem('company');
            localStorage.removeItem('email');
            await Auth.signOut();
            //dispatch(actionCreators.signIn(false, ''))
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    return (
        <AuthContext.Provider value={{
            authState: authState,
            dispatch: dispatch,
            signOut: signOut
        }}>
            {props.children}
        </AuthContext.Provider>

    )
}

