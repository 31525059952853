import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import solidGauge from "highcharts/modules/solid-gauge";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from 'highcharts/modules/exporting';
import darkUnicaTheme from 'highcharts/themes/dark-unica';

// Apply the dark theme
darkUnicaTheme(Highcharts);
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
solidGauge(Highcharts);
export default function Chart( props: any) {


    return (
        <div className="custom-chart">
            <HighchartsReact
                highcharts={Highcharts}
                ref={props.innerRef}
                options={props.chartOptions}
                containerProps={{ style: { height: "100%"}}}
            />
        </div>
    );
}