import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import {API, graphqlOperation, Storage} from 'aws-amplify';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';

import Image from 'react-bootstrap/Image';

import {useParams} from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';
import {Roller} from "react-spinners-css";


import {LinkContainer} from "react-router-bootstrap";


import Spinner from "react-bootstrap/Spinner";

//reuse global

interface IProductImgProps{

    status: string,
    imgUrl: string,
}

export default function DeviceImg() {

    return (
        <div className={'productPageImageBorder'}>
            {
                    <img
                        src={process.env.PUBLIC_URL + '/morph.png'}
                    />

            }
        </div>
    )
}
