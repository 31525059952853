interface IUser {
    userName: string,
    email: string,
    company: string

}

interface AuthState{
    state: string
    loading: boolean,
    error: string,
    user: IUser | null | undefined
}

interface IAuthPayload {
    loading: boolean,
    error: string,
    user?: IUser | null | undefined
}

interface AuthAction{

    type: string
    payload: IAuthPayload

}

export function authReducer(authState : AuthState, action : AuthAction) {

    const loading = action?.payload?.loading;
    const error = action?.payload?.error;
    const user = action?.payload?.user;

    switch (action.type) {
        case "signIn":
            return { ...authState, state:'signIn', loading: loading, error: error, user: null }
        case "signedIn":
            return { ...authState, state:'signedIn', loading: loading, error: error, user: user}
        case "signOut":
            return { ...authState, state:'signOut', loading: loading, error: error, user: null}
        case "resetPassword":
            return { ...authState, state:'resetPassword', loading: loading, error: error, user: null }
        case "newPassword":
            return { ...authState, state:'newPassword', loading: loading, error: error, user: null }
        default:
            return { ...authState, state:'signIn', loading: false, error: '', user: null }
    }
}
