import {AuthProvider} from "./AuthContext";

interface IAppProviderProps {

    children: JSX.Element;
}

export default function AppProvider(props: IAppProviderProps){

    return (
            <AuthProvider>
                {props.children}
            </AuthProvider>
    )

}
