import React from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import AppRouter from './navigation/Router'
import awsconfig from './aws-exports';
import AppProvider from "./contexts/AppProvider";


Amplify.configure(awsconfig);

function App(): JSX.Element {
    return (
        <AppProvider>
                <AppRouter/>
        </AppProvider>

    );
}

export default App;


