import MessageCard from "./MessageCard";
export default function MessageQueue(props:any){

    // const messageElements = props.messages.map((message : any ,index: any) => {
    //     props.cycle.current = props.cycle.current + 1
    //     return(
    //         // <MessageCard
    //         //     key={index + props.cycle.current}
    //         //     deviceName={message.name}
    //         //     message={message.message}
    //         //     render={props.render}
    //         // />
    //         <div className="messageItem">{message.message}</div>
    //     )
    // })

    let rand = Math.floor(Math.random() * 1000000) + 1

    return(
        props.message !== undefined ?  <>
                <div className={`messageTitle ${props.render ? "showDeviceName" : ""}`}>{props.message?.name}</div>
                <div className={"slider"}>
                    <div className={`messageItem ${props.render ? "slideMessage" : ""}`}>{props.message?.message}</div>
                </div>
            </> : null
    )
}
