import React, {useContext, useState} from "react";
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Toast from 'react-bootstrap/Toast';
import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';
import * as Icon from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import Popover from 'react-bootstrap/Popover';

import PrivateRoute from "./PrivateRoute";

import Activity from '../pages/activity/DarkActivity';

import Map from '../pages/map/Overwatch';

import PredictionEC from '../pages/predictionEC/DarkPrediction';
import PredictionAP from '../pages/predictionAP/DarkPrediction';
import Device from "../pages/device";
import DeviceSummaryContainer from "../pages/deviceSummary";
import DeviceMonitoringContainer from "../pages/deviceMonitoring";



import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    Link,
    redirect,
    NavLink
} from "react-router-dom";

import {AuthContext} from "../contexts/AuthContext";
import HeatMap from "../pages/heatMap/HeatMap";
import Prediction from "../pages/prediction/Prediction";


//TODO: view component of sidebar and responsive view mobile and route config splite to small view compenet and add container component for the logic

export default function AppNavBar({...props}) {


    return (
        <Container fluid>
                    <Routes>
                        <Route path="/map" element={<Map/>}/>
                        <Route path="/activity" element={<Activity/>}/>
                        <Route path="/activity" element={<Activity/>}/>
                        <Route path="/prediction" element={<Prediction />}/>
                        <Route path="/heatmap" element={<HeatMap />}/>
                        <Route path="/device/"
                               element={<Device/>}>
                            <Route
                                path="summary"
                                element={<DeviceSummaryContainer/>}
                            />

                            <Route path="monitoring" element={<DeviceMonitoringContainer/>}/>

                        </Route>
                        <Route path="*" element={<Navigate to="/map" replace/>}/>
                    </Routes>
        </Container>
    );
}


/*

<NavLink to="/patients"
                                             className={({isActive}) => {
                                                 if (isActive || window.location.toString().includes('patient') ||
                                                     window.location.toString().includes('patientForm')) {
                                                     return "sideBarLabel selected";
                                                 } else {
                                                     return 'sideBarLabel';
                                                 }
                                             }}>
                                        <Row>
                                            <Col md={{span: 2, offset: 1}}>
                                                {
                                                    sideBarActive ?
                                                        <Icon.PersonBoundingBox size={16}/> :
                                                        <OverlayTrigger
                                                            placement="right"
                                                            delay={{show: 50, hide: 100}}
                                                            overlay={
                                                                <span className={'sideBarTooltip'}>
                                                                    Patients
                                                            </span>
                                                            }
                                                        >
                                                            <Icon.PersonBoundingBox size={16}/>
                                                        </OverlayTrigger>
                                                }
                                            </Col>
                                            <Col md={3}>
                                                <span
                                                    className={sideBarActive ? 'showSideBar' : 'hideSideBar'}> Patients</span>
                                            </Col>
                                        </Row>
                                    </NavLink>

 */

