import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import darkUnicaTheme from 'highcharts/themes/dark-unica';
import HighchartsHeatmap from "highcharts/modules/heatmap";

// Apply the dark theme
darkUnicaTheme(Highcharts);
HighchartsHeatmap(Highcharts)
export default function Chart( props: any) {


    return (
        <div className="custom-chart">
            <HighchartsReact
                highcharts={Highcharts}
                ref={props.innerRef}
                options={props.chartOptions}
                containerProps={{ style: { height: "100%"}}}
            />
        </div>
    );
}