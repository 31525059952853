import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AuthContext} from "../../../contexts/AuthContext";
import {actionCreators} from '../../../state_management/actions/authActions'
import {LoginContext} from "../LoginContainer";


export default function ResetPasswordForm(){

    const {dispatch, authState} = useContext(AuthContext);
    const {loginForm, onChange ,signIn, resetPassword} = useContext(LoginContext);
    let error = authState.error;


    return(
        <>
            <Form noValidate validated={error !== ''} >
                <div className={'title'}>Reset your password</div>
                <Form.Group controlId="username">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                        value={loginForm.username}
                        placeholder="username"
                        type="text"
                        maxLength={30}
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
                {authState.loading ?
                    <Row>
                        <Col md={{span: 4, offset: 4}}>
                            <Roller color="#70db70"  className={'spinnerSubmit'}/>
                        </Col>
                    </Row>
                    :
                    <Button variant="outline-success" onClick={resetPassword}>
                        Send Code<Icon.ShieldCheck size={20}/>
                    </Button>
                }
            </Form>
            <Form.Label className={'changeStateMessageLabel'}>No Account?
                <span className={'changeStateMessage'}
                      onClick={() => {
                          dispatch(actionCreators.signIn(false, ""))
                      }}>
                        Back to Sign In
                </span>
            </Form.Label>
            {error === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error}
                    </Col>
                </Row>
            }
        </>
    )
}


