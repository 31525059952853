import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DeviceList from "../../components/layout/DeviceList"
import React, {useEffect, useRef, useState} from "react";
import {API, graphqlOperation} from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';

import devicesList from "./devices.json"
import DevicesTabs from "./view-components/DevicesTabs";
import "./Activity.css"
import Header from "../../components/layout/Header";
export default function DarkActivity(){
    const [activeDevice, setActiveDevice] = useState<string>("");
    const [render,setRender] = useState<number>(0)
    const deviceIndex = useRef<number>(0)

    useEffect(() => {
        setActiveDevice(()=> devicesList[0].name)
        setInterval(()=>{
            if(deviceIndex.current > devicesList.length - 1)
                deviceIndex.current = 0
            setActiveDevice(()=> devicesList[deviceIndex.current].name)
            deviceIndex.current = deviceIndex.current + 1
        },15000)

        setInterval(() => {
            setRender(prevState => prevState + 1)
        }, 1100);

        const subscriptionWater = API.graphql(graphqlOperation(subscriptions.onOS))
            // fix type observerable
            // @ts-ignore
            .subscribe({
                next: ({provider, value}: any) => {
                    console.log(value.data.onOS)
                    /*
                    setDeviceWater({
                        id: '111',
                        timestamp: '2022-06-30 10:49:04.565',
                        waterPressure: value.data.onWater.waterPressure,
                        waterFlowRate: value.data.onWater.waterFlowRate

                    })

                     */
                },
                error: (error: Error) => console.warn(error)
            });

    },[])

    return(
        <Container className="overWatchActivity">
            <Row className="header">
                <Col className="logo" md={1}>
                    <img src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'} />
                </Col>
                <Col className={'oncoTitle'}>
                    <h2>
                        System OS Monitoring
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col>
                    <DevicesTabs
                        activeDevice={activeDevice}
                        render={render}
                    />
                </Col>
            </Row>
        </Container>
    )
}
