import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Highcharts from "highcharts-react-official";
import Chart from "../../../components/layout/Chart";
import {IDeviceLiveActivity} from "../API";
import {useEffect} from "react";
import {useRef} from "react";
export default function DeviceStats(props: any){
    const upTime = useRef<string>("")
    const deviceInfo = useRef<IDeviceLiveActivity>(props.deviceInitData)

    const gpuChartOptions = useRef<Highcharts.Props>({})
    const cpuChartOptions = useRef<Highcharts.Props>({})
    const memChartOptions = useRef<Highcharts.Props>({})
    const averageWorkTime = useRef<Highcharts.Props>({})

    const lifeCycle = useRef(0)

    const chartComponentRef1:any = useRef(null);
    const chartComponentRef2:any = useRef(null);
    const chartComponentRef3:any = useRef(null);
    const chartComponentRef5:any = useRef(null);

    useEffect(()=>{

        gpuChartOptions.current = {
            chart: {
                type: 'spline',
                height: window.innerWidth* 0.19
            },
            title: {
                text: "Network",style: {
                    fontSize: 25,
                    fontFamily: "Seven Segment",
                    textTransform: false,
                }
            },
            accessibility: {
                screenReaderSection: {
                    beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
                }
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 15,
                title: {
                    text: 'Time',
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                }
            }, yAxis: [{
                title: {
                    text: "Utilization %",
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                },
                max: 100
            }],
            series: [{
                name: 'GPU data',
                data: (function () {
                    // generate an array of random data
                    let data = [],
                        time = (new Date()).getTime(),
                        i;
                    for (i = -60; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y:  0
                        });
                    }
                    return data;
                }()),
                color:"#AED6F1"
            }],
            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false,
            }
        }
        cpuChartOptions.current = {
            chart: {
                type: 'spline',
                height: window.innerWidth* 0.19
            },
            title: {
                text: "CPU",
                style: {
                    fontSize: 25,
                    fontFamily: "Seven Segment"
                }
            },
            accessibility: {
                screenReaderSection: {
                    beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
                }
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 15,
                title: {
                    text: 'Time',
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                }
            }, yAxis: [{
                title: {
                    text: "Utilization %",
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                },
                max: 100
            }],
            series: [{
                name: 'CPU data',
                data: (function () {
                    // generate an array of random data
                    let data = [],
                        time = (new Date()).getTime(),
                        i;
                    for (i = -60; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y:  0
                        });
                    }
                    return data;
                }()),
                color:"#AED6F1"
            }],
            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false,
            }
        }
        memChartOptions.current = {
            chart: {
                type: 'spline',
                height: window.innerWidth* 0.19
            },
            title: {
                text: "Memory",
                style: {
                    fontSize: 25,
                    fontFamily: "Seven Segment"
                }
            },
            accessibility: {
                screenReaderSection: {
                    beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
                }
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 15,
                title: {
                    text: 'Time',
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                }
            }, yAxis: [{
                title: {
                    text: "Utilization %",
                    style: {
                        fontSize: 20,
                        fontFamily: "Seven Segment"
                    }
                },
                max: 100
            }],
            series: [{
                name: 'Memory data',
                data: (function () {
                    // generate an array of random data
                    let data = [],
                        time = (new Date()).getTime(),
                        i;
                    for (i = -60; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y:  0
                        });
                    }
                    return data;
                }()),
                color:"#AED6F1"
            }],
            legend: {
                enabled: false
            },

            exporting: {
                enabled: false
            },
            credits: {
                enabled: false,
            }
        }

        averageWorkTime.current = {
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                height: window.innerWidth < 2000 ? 250 : 420
            },
            title: {
                text: 'Device activity',
                align: 'left',
                style: {
                    fontSize: 25,
                    fontFamily: "Seven Segment",
                    textTransform: false,
                }
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b><br>{point.percentage:.1f} %',
                        distance: window.innerWidth < 2400 ? -35 : -70,
                        style: {
                            fontSize:  window.innerWidth < 2400 ? 15 : 25
                        }

                    },
                    showInLegend: true
                }
            },
            series: [{
                name: 'Percent',
                colorByPoint: true,
                data: [{
                    name: 'Online',
                    y: 95,
                    color: "#80dfff"
                },  {
                    name: 'Offline',
                    y:  5,
                    color: "#e0ebeb",
                }]
            }]
        }

        const chart1 = chartComponentRef1.current?.chart;
        const chart2 = chartComponentRef2.current?.chart;
        const chart3 = chartComponentRef3.current?.chart;
        const chart4 = chartComponentRef5.current?.chart;

        chart1?.update({
            chart: {
                width: 350
            }
        });
        chart2?.update({
            chart: {
                width: 350
            }
        });
        chart3?.update({
            chart: {
                width: 350
            }
        });
        chart4?.update({
            chart: {
                width: 350
            }
        });
    },[])

    useEffect(()=>{
        lifeCycle.current++
        const date = new Date((deviceInfo.current.info.upTime + lifeCycle.current) * 1000);
        upTime.current = date.toTimeString().split(' ')[0]
        const processes = deviceInfo.current.info.processes + Math.ceil(Math.random() * 4) * (Math.round(Math.random()) ? 1 : -1)
        const threads = deviceInfo.current.info.threads + Math.ceil(Math.random() * 10) * (Math.round(Math.random()) ? 1 : -1)
        deviceInfo.current.info.processes = processes
        deviceInfo.current.info.threads = threads

        let x = (new Date()).getTime() // current time
        let y1 = Math.ceil( Math.random() * (60 - 20 + 1) + 20),
            y2 = Math.ceil(Math.random() * (80 - 40 + 1) + 40),
            y3 = Math.ceil(Math.random() * (70 - 40 + 1) + 40);
        cpuChartOptions.current?.series[0].data.shift()
        cpuChartOptions.current.series[0].data = [...cpuChartOptions.current.series[0].data,[x, y1]];
        memChartOptions.current?.series[0].data.shift()
        memChartOptions.current.series[0].data = [...memChartOptions.current.series[0].data,[x, y2]];
        gpuChartOptions.current?.series[0].data.shift()
        gpuChartOptions.current.series[0].data = [...gpuChartOptions.current.series[0].data,[x, y3]];
    },[props.render])


    return(
        <Container className="deviceStats">
            {lifeCycle.current > 0 ? <>
                <Row className="mb-4">
                    <Col className="deviceInfo">
                        <h4 className="text-center">Device Info</h4>
                        <p>Name: <span className="info"> {deviceInfo.current.info.deviceName} </span> </p>
                        <p>ID: <span className="info">{deviceInfo.current.id}</span> </p>
                        <p>CPU: <span className="info">{deviceInfo.current.info.cpuName}</span> </p>
                        <div className="d-flex gap-5 mt-2">
                            <div>
                                <p>Processes</p>
                                <p className="info">{deviceInfo.current.info.processes}</p>
                            </div>
                            <div>
                                <p>Threads</p>
                                <p className="info">{deviceInfo.current.info.threads}</p>
                            </div>
                            <div>
                                <p>Up time</p>
                                <p className="info">{upTime.current}</p>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <Chart chartOptions={averageWorkTime.current} innerRef={chartComponentRef5}/>
                    </Col>
                </Row>
                <Row className="charts">
                    <Row className="noPadding">
                        <Col className="noPadding">
                            <Chart chartOptions={cpuChartOptions.current} innerRef={chartComponentRef1}/>
                        </Col>
                        <Col>
                            <Chart chartOptions={gpuChartOptions.current} innerRef={chartComponentRef2}/>
                        </Col>
                        <Col className="noPadding">
                            <Chart chartOptions={memChartOptions.current} innerRef={chartComponentRef3}/>
                        </Col>
                    </Row>
                </Row>
            </> : <div className="spinner"><Spinner animation="border" /></div>}
        </Container>
    )
}
