import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, {useEffect, useRef, useState} from "react";
import CustomMarker from "./view-components/CustomMarker";
import markers from "./markers.json"
import MessageQueue from "./view-components/MessageQueue";
import Map from "./view-components/Map";
import "./map.css"
import Header from "../../components/layout/Header";
import {API, graphqlOperation} from "aws-amplify";
import * as subscriptions from "../../graphql/subscriptions";

export default function Overwatch(){

    const [markersComponents, setMarkersComponents] = useState<Array<any>>([]);
    const [message,setMessage] = useState<Array<any>>([])

    const mapCenter = useRef<any>({})
    const msgArray = useRef([])
    const render = useRef(true)

    useEffect(()=>{



        setInterval(()=>{
            const clinics = ["2000-1234","2000-1237", "2000-1322"]
            const m20001234 =["QC: Required",
                "Enabled Energies: 50,60",
                "Installed x-ray tubes: Wide",
                "Total Logs: 48",
                "Total Emissions: 17"]
            const m20001237 =["QC: Required",
                "Enabled Energies: 50,60,70,80,90,100",
                "Installed x-ray tubes: Wide",
                "Total Logs: 5",
                "Total Emissions: 0"]
            const m20001322 =["QC: Complete",
                "Enabled Energies: 50,60,70",
                "Installed x-ray tubes: Narrow",
                "Total Logs: 11",
                "Total Emissions: 4"]
            let clinicName = clinics[Math.floor(Math.random()*clinics.length)];
            let message: string;
            if(clinicName ==="2000-1234"){
                message= m20001234[Math.floor(Math.random()*m20001234.length)]

            }
            else if(clinicName ==="2000-1237"){
                message= m20001237[Math.floor(Math.random()*m20001237.length)]

            }
            else if(clinicName ==="2000-1322"){
                message= m20001322[Math.floor(Math.random()*m20001322.length)]

            }

            // @ts-ignore
            msgArray.current.push({name: clinicName, message: message})

            const currMsg = msgArray.current.shift()
            // @ts-ignore
            setMessage(() => {
                return currMsg
            })
            toggleDevice(clinicName)

            //toggleDevice(clinicName)
        },11000)
        console.log(msgArray,"msgArray")
        /*
        const subscriptionLogger = API.graphql(graphqlOperation(subscriptions.onLog))
            // fix type observerable
            // @ts-ignore
            .subscribe({
                next: ({provider, value}: any) => {
                    console.log(value.data.onLog)
                    const devices = ["2000-1234","2000-1237","2000-1238",
                        "2001-1324","2001-1324","2001-1244","2001-1122",
                        "2000-1322","2000-1333"]
                    let name = value.data.onLog.deviceID;
                    let device = devices[Math.floor(Math.random()*devices.length)];
                    // let device = name.substr(0, 4) + '-' + name.substr(4);
                    // setMessage(prevState => {
                    //     if(prevState.length > 0)
                    //         prevState.pop()
                    //     return [{name: device, message: value.data.onLog.Message}]
                    // })
                    // toggleDevice(device)
                    msgArray.current.push({name: device, message: value.data.onLog.Message})
                },
                error: (error: Error) => console.warn(error)
            });

         */
    },[])

    useEffect(()=>{
        let lat:number = 0,lng:number = 0 ;
        markers.forEach(marker => {
            lat += marker.lat
            lng += marker.lng
        })
        mapCenter.current = {lat: (lat/markers.length), lng: (lng/markers.length)}

        setMarkersComponents(()=> markers)
    }, [])

    function toggleDevice(clinicName:string){
        render.current = !render.current
        setMarkersComponents(prevState => {
            return prevState.reduce((total,currVal) => {
                if(currVal.name === clinicName){
                    return [...total,{...currVal,active: !currVal.active}]
                }else
                    return [...total,currVal]
            },[])
        })
    }

    const markerElements = markersComponents.map((marker:any,index:number) => {
        return (
            <CustomMarker
                key={index}
                lat={marker.lat}
                lng={marker.lng}
                toggleDevice={toggleDevice}
                clinicName={marker.name}
                active={marker.active}
            />
        )
    })

    return(
        <Container className="overwatch">
            <Row className="header">
                <Col className="logo" md={1}>
                    <img src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'} />
                </Col>
                <Col className={'oncoTitle'}>
                    <h2>
                        Geosystem Realtime Monitoring
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Row style={{ height: '73vh', width: '100%' , margin:'unset'}}>
                        <Map
                            markers={markerElements}
                            mapCenter={mapCenter.current}
                        />
                    </Row>
                    <Row
                        // className={"logMessages shadow"}
                        style={{marginTop: "15px"}}>
                        <MessageQueue
                            message={message}
                            render={render.current}
                        />
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
