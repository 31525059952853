interface IUser {
    userName: string,
    email: string,
    company: string
}


const types = {
    signIn: 'signIn',
    signedIn: 'signedIn',
    signOut: 'signOut',
    resetPassword: 'resetPassword',
    newPassword: 'newPassword'
}

// use enum

export const actionCreators = {
    signIn: (loading : boolean, error : string) => ({
        type: types.signIn,
        payload: {loading, error},
    }),
    signedIn: (loading : boolean, error : string, user: IUser | null) => ({
        type: types.signedIn,
        payload: {loading, error, user},
    }),
    signOut: (loading : boolean, error : string) => ({
        type: types.signOut,
        payload: {loading, error},
    }),
    resetPassword: (loading : boolean, error : string) => ({
        type: types.resetPassword,
        payload: {loading, error},
    }),
    newPassword: (loading : boolean, error : string) => ({
        type: types.newPassword,
        payload: {loading, error},
    }),
}
