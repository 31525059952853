import {useEffect} from "react";
import {Marker} from '@react-google-maps/api';
// @ts-ignore
import deviceIcon from "../../../assets/morph.png"
export default function CustomMarker(props:any){
    const name = props.clinicName

    useEffect(()=>{
        if(props.active){
            setTimeout(() => {
                props.toggleDevice(name)
            }, 2000);
        }
    },[props.active])

    return(
            <Marker
                icon={{
                    url: deviceIcon,
                    labelOrigin: new window.google.maps.Point(38,50),
                    scaledSize: new window.google.maps.Size(60,45)}}
                position={{ lat: props.lat, lng: props.lng }}
                label={{
                    text:props.clinicName,
                    className: props.active ? "deviceInfo highlightMarker" : "deviceInfo"
                }}
                animation={props.active ? window.google.maps.Animation.BOUNCE : undefined}
            />
    )
}
