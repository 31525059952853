import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CountUp from 'react-countup';
import React from "react";


export default function HeatStats(props:any){

    return(
        <Container className={'heatStats'}>
            <Row>
                <Row>
                    <h3 className={'statsTitle'}>positive correlation </h3>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[0]["value"]}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[0]["name"]}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[1].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[1]["name"]}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[2].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[2]["name"]}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[3].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[3]["name"]}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row>
                <h3 className={'statsTitle'}>negative correlation </h3>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[4].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[4]["name"]}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[5].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[5]["name"]}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[6].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[6]["name"]}
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[7].value}
                                    decimals={4}
                                    duration={3}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                {props.stats[7]["name"]}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}
