import {faker} from '@faker-js/faker';
import {IDeviceLiveActivity} from "./API";

export const devicesDATA =  [
    {
        "id": "525bb598-d55a-4818-82b1-4948519362c5",
        "companyID": "4e635ed8-c7c7-4e11-8f84-94d4a7db0dcb",
        "name": "Tulsa Skin Center",
        "devices": {
            "items": [
                {
                    "id": "e5c4f3b3-f06b-4302-b603-ea1e5e091c6b",
                    "serialNumber": "2000-1234",
                    "typeID": "75361d6b-b2d7-4291-9ab2-67e7f5992448",
                    active: true
                },
                {
                    "id": "7f503ef1-85b5-4b0a-8ce9-d94ba6d290a0",
                    "serialNumber": "2000-1237",
                    "typeID": "64b1c61a-a759-4dc6-a03f-8e645b960f72.png",
                    active: true
                }
            ]
        }
    }
]

export const messagesDATA = ["Device started working on patient","Device Stopped working on patient"
                        ,"Critical sensor failure detected. Alerting medical personnel"
                        ,"Device powered on. Self-test passed. Boot time: 5 seconds"
                        ,"Device shutting down. Saving data and closing connections."
                        ,"User Dr. Johnson accessed patient records."
                        ,"Device turned on."
                        ,"Data sent to remote server."]
export const devicesFakeData: Array<IDeviceLiveActivity> =  []

const devices = ['63766a94-2f2c-427b-b63d-f64854d06e02',
    'e5c4f3b3-f06b-4302-b603-ea1e5e091c6b',
    '7f503ef1-85b5-4b0a-8ce9-d94ba6d290a0',
    '860d0ded-c627-43a1-908f-b38c27a6d5c5', 'a548e0a3-9887-4374-9781-324bbf5b3ba9',
    '2def6d2d-01d1-48b4-85f7-943cba5d0b56']

devices.forEach(device =>{
    let deviceInfo: IDeviceLiveActivity = {
        id: device,
        info:{
            cpuName: "11th Gen Intel(R) Core(TM) i9-11900H @ 2.50GHz",
            deviceName: "Morpheus",
            processes: faker.number.int({max: 300, min: 200}),
            threads: faker.number.int({max: 4000, min: 2000}),
            upTime: faker.number.int({max: 10000, min: 1000})
        },
        cpu: {
            utilized: faker.number.int({max: 100, min: 15})
        },
        memory:{
            utilized: faker.number.int({max: 100, min: 15})
        },
        logs:{
            messages: []
        }
    }
    devicesFakeData.push(deviceInfo)
})










