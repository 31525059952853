import React, {lazy, Suspense, useContext, useEffect} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    redirect
} from "react-router-dom";

import AppNavbar from "./Navbar";
import {AuthContext} from "../contexts/AuthContext";

import Login from '../pages/login';
import Dashboard from '../pages/map/Overwatch';

import './navigation.css'

// TODO: use dynamic import or react lazy and context

// Todo: use router 6
// Todo: refactor
export default function AppRouter() {


    const {authState} = useContext(AuthContext);


    return (
        <Router>
            {

            authState.state === 'signedIn' ?
                <AppNavbar/> :
                <Login/>
            }
        </Router>

    );
}




