import React, {useContext, useEffect, useRef, useState, createContext} from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import {API, graphqlOperation, Storage} from 'aws-amplify';
import {deleteDevice} from '../../graphql/mutations';
import {getClinic} from '../../graphql/queries';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';

import Image from 'react-bootstrap/Image';

import {useParams} from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';
import {Roller} from "react-spinners-css";

import './device.css';


import {LinkContainer} from "react-router-bootstrap";


import Spinner from "react-bootstrap/Spinner";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import Nav from 'react-bootstrap/Nav';

import {Outlet} from 'react-router-dom';


import {S3ProviderListOutput} from '@aws-amplify/storage/lib/types/AWSS3Provider';
import {GraphQLResult} from '@aws-amplify/api-graphql/lib/types/index';

import ItemContainerWrapper from '../../components/layout/ItemContainerWrapper';

import DeviceInfo from './view-components/DeviceInfo';
import DeviceImg from './view-components/DeviceImg';


import {
    BrowserRouter as Router,
    Route,
    useLocation,
    matchRoutes,
    Link,
    redirect,
    NavLink
} from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Container from "react-bootstrap/Container";
import Header from "../../components/layout/Header";
import DeviceList from "../../components/layout/DeviceList";
import DevicesTabs from "../activity/view-components/DevicesTabs";
import devicesList from "./devices.json";
import CountUp from 'react-countup';


//reuse global interface

//reuse global

interface IProduct {

    id: string,
    name: string,
    description: string,
    productImgUrl?: string
}

interface IClinic {

    id: string,
    name: string,
    lat: number,
    lng: number,
    address: string,
    companyID: string,
    company: { id: string }
}

interface IDevice {

    id: string
    serialNumber: string
    deviceImgUrl?: string
    type: IProduct
    currentClinic: IClinic

}

interface IUrlIdParam {

    id: string
    serialNumber: string,
    clinicId: string,
    clinicName: string,
    typeId: string,
    productName: string
}

interface IStatusImg {

    status: string,
    imgUrl: string
}


// reuse global
interface IDeleteInput {

    id: string
}

interface IDeleteState {

    status: string,
    message: string
}

interface IClinicItem {

    getClinic: IClinic
}

export const ActiveContext = createContext('');


// generic component props childern

export default function DeviceContainer() {

    const [location, setLocation] = useState<string>("")

    const [activeDevice, setActiveDevice] = useState<string>("2000-1237");
    const deviceIndex = useRef<number>(0)

    const [mon, setMon] = useState<boolean>(false)



    useEffect(() => {
        //fix this by let of selected tab
        const location = window.location.pathname
        if (location.includes("summary")) {
            setLocation(location.replace('summary', ''));
        } else if (location.includes("Logs")) {
            setLocation(location.replace('Logs', ''));
        } else if (location.includes("monitoring")) {
            setLocation(location.replace('monitoring', ''));
            setMon(true)
        } else if (location.includes("Stability")) {
            setLocation(location.replace('Stability', ''));
        } else if (location.includes("QC")) {
            setLocation(location.replace('QC', ''));
        } else if (location.includes("Service")) {
            setLocation(location.replace('Service', ''));
        }


        setActiveDevice(() => devicesList[0].name)
        setInterval(() => {
            if (deviceIndex.current > devicesList.length - 1)
                deviceIndex.current = 0
            setActiveDevice(() => devicesList[deviceIndex.current].name)
            deviceIndex.current = deviceIndex.current + 1
        }, 10000)


    }, [])

    function getAddress(): string {

        const device = devicesList.find(device => device.name === activeDevice)

        if (device) {

            return device?.address

        } else return ""

    }

debugger
    // change props
    // @ts-ignore
    return (

        <Container className="overwatch">
            <Row className="header">
                <Col className="logo" md={1}>
                    <img src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'} />
                </Col>
                <Col className={'oncoTitle'}>
                    <h2>
                        {mon? 'System Physiological Monitoring' : 'System Log Activity'}
                    </h2>
                </Col>
            </Row>
            <Row className={'deviceFont'}>
                {
                    /*
                    <Col md={2}>
                    <DeviceList
                        deviceList={devicesList}
                        activeDevice={activeDevice}
                    />
                </Col>
                     */
                }

                <Col md={4}>

                    <DeviceInfo
                        serialNumber={activeDevice}
                        location={getAddress()}
                    />


                </Col>

                <Col md={4}>
                    <DeviceImg/>
                </Col>
                <Col md={4}>
                    <Row>
                        <Col>
                            <div className={'cardLayout'}>
                                    <div className={'cardData'}>
                                        <CountUp
                                            end={activeDevice==="2000-1234" ?16 : 19}
                                            duration={3}
                                        />
                                    </div>
                                    <div className={'cardDescription'}>
                                        Sessions
                                    </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={'cardLayout'}>
                                    <div className={'cardData'}>
                                        <CountUp
                                            end={activeDevice==="2000-1234"? 2 : 5}
                                            duration={3}
                                        />
                                    </div>
                                    <div className={'cardDescription'}>
                                        Interlocks
                                    </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={'cardLayout'}>

                                    <div className={'cardData'}>
                                        <CountUp
                                            end={activeDevice==="2000-1234"? 5: 7}
                                            duration={3}
                                        />

                                    </div>
                                    <div className={'cardDescription'}>
                                        QC
                                    </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={'cardLayout'}>
                                <div>
                                    <div className={'cardData'}>
                                        <CountUp
                                            end={activeDevice==="2000-1234" ? 66 : 88}
                                            duration={3}
                                        />
                                    </div>
                                    <div className={'cardDescription'}>
                                        System Logs
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={'cardLayout'}>
                                <div>
                                    <div className={'cardData'}>
                                        <CountUp
                                            end={activeDevice==="2000-1234"? 78: 98}
                                            duration={3}
                                        />
                                    </div>
                                    <div className={'cardDescription'}>
                                        Physics Logs
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className={'cardLayout'}>
                                    <div className={'cardData'}>
                                        <CountUp
                                            end={ activeDevice==="2000-1234"? 27888: 32443}
                                            duration={3}
                                        />
                                    </div>
                                    <div className={'cardDescription'}>
                                        Total Logs
                                    </div>
                            </div>
                        </Col>
                    </Row>
                </Col >

            </Row>
            <Row>
                <Col>
                    <ActiveContext.Provider value={activeDevice}>
                        <Row className={'deviceTabsBtn'}>
                            <Col>
                                <Nav defaultActiveKey={"1"}>
                                    <Nav.Item>
                                        <NavLink to={location + "summary"} onClick={()=> setMon(false)}
                                                 className={({isActive}) => (isActive ? "tabSelected" : "")}>
                                            <div className={'tabDiv'}>
                                    <span className={'tabDivSpan'}>
                                    Summary
                                    </span>
                                                <Icon.ListCheck className={'tabIcon'} size={16}/>
                                            </div>

                                        </NavLink>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <NavLink to={location + "monitoring"} onClick={()=> setMon(true)}
                                                 className={({isActive}) => (isActive ? "tabSelected" : "")}>
                                            <div className={'tabDiv'}>
                                            <span className={'tabDivSpan'}>
                                                    Monitoring
                                            </span>
                                                <Icon.BrowserSafari className={'tabIcon'} size={16}/>
                                            </div>
                                        </NavLink>
                                    </Nav.Item>

                                </Nav>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Outlet/>
                            </Col>
                        </Row>
                    </ActiveContext.Provider>

                </Col>

            </Row>
        </Container>
    )

}
