import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../../components/layout/Header";
import "./prediction.css"
import React, {useEffect, useRef, useState} from "react";
import {PredictionChart} from "./API";
import Chart from "../../components/layout/Chart";
import PredStats from "./view-components/PredStats";

import apData1 from "./APdataAUG.json"
import ecData1 from "./ECdataAUG.json"
import data1 from "./data1.json"
import data2 from "./data2.json"
import stats1 from "./stats1.json"
import stats2 from "./stats2.json"

export default function Prediction(){

    // const [charts1, setCharts1] = useState<Array<PredictionChart>>([])
    // const [charts2, setCharts2] = useState<Array<PredictionChart>>([])
    const [render, setRender] = useState<boolean>(true)

    const charts1 = useRef([])
    const charts2 = useRef([])

    useEffect(()=>{
        try {
            generateCharts1234()
            generateCharts1237()
        }catch (e: any) {
            console.log(e.message)
        }

        setInterval(()=>{
            setRender(prevState => !prevState)
        },10000)

    },[])
    function generateCharts1234() {
        const predictions = data1
        let modifiedCharts = predictions.map((chart: any) => {
            let baseValues: any = []
            chart.charts.forEach((chart: any) => {
                baseValues = chart.map((item: any) => [item.timestamp, item.baseVal])
            })

            let validationSet: any
            chart.charts.forEach((chart: any) => {
                validationSet = chart.filter((item: any) => item.baseVal !== null)
            })
            const validation = validationSet.map((item: any) => {
                return [item.timestamp, item.prediction]
            })

            let predictionSet: any
            chart.charts.forEach((chart: any) => {
                predictionSet = chart.filter((item: any) => item.baseVal === null)
            })

            const futureSet = predictionSet.map((item: any) => {
                return [item.timestamp, item.prediction]
            })


            return {
                chart: {
                    zoomType: 'x',
                    backgroundColor: '#171718',
                    height: window.innerWidth * 0.175
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: chart.name + " (min " + chart.min + "-max " + chart.max + ")",
                    align: "left",
                    x: 25,
                    style: {
                        fontSize: 25,
                        fontFamily: 'Seven Segment',
                        textTransform: false,
                    }
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
                    }
                },
                tooltip: {
                    valueDecimals: 8
                },
                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 15,
                    labels: {
                        format: '{value: %d/%m/%y %H:%M:%S}',
                    },
                }, yAxis: [{
                    title: {
                        text: chart.metric,
                        style: {
                            fontSize: 18,
                            fontFamily: 'Seven Segment',
                            textTransform: false,
                        }
                    },
                    plotLines: [{
                        color: '#ff3300',
                        width: 2,
                        value: chart.max
                    }],
                    min: chart.viewMin,
                    max: chart.viewMax
                }],
                series: [{
                    name: "True Values", type: "spline", data: baseValues, color: "#1aa3ff"
                },
                    {
                        name: "Validated Values", type: "spline", data: validation, color: "#ff8c1a"
                    },
                    {
                        name: "predicted Values", type: "spline", data: futureSet, color: "#3caef1"
                    }
                ],
                credits: {
                    enabled: false,
                }
            }
        })

        // setCharts1(modifiedCharts)
        charts1.current = modifiedCharts
    }
    function generateCharts1237() {
        const predictions = data2
        let modifiedCharts = predictions.map((chart: any) => {
            let baseValues: any = []
            chart.charts.forEach((chart: any) => {
                baseValues = chart.map((item: any) => [item.timestamp, item.baseVal])
            })

            let validationSet: any
            chart.charts.forEach((chart: any) => {
                validationSet = chart.filter((item: any) => item.baseVal !== null)
            })
            const validation = validationSet.map((item: any) => {
                return [item.timestamp, item.prediction]
            })

            let predictionSet: any
            chart.charts.forEach((chart: any) => {
                predictionSet = chart.filter((item: any) => item.baseVal === null)
            })

            const futureSet = predictionSet.map((item: any) => {
                return [item.timestamp, item.prediction]
            })


            return {
                chart: {
                    zoomType: 'x',
                    backgroundColor: '#171718',
                    height: window.innerWidth * 0.175
                },
                exporting: {
                    enabled: false
                },
                title: {
                    text: chart.name + " (min " + chart.min + "-max " + chart.max + ")",
                    align: "left",
                    x: 25,
                    style: {
                        fontSize: 25,
                        fontFamily: 'Seven Segment',
                        textTransform: false,
                    }
                },
                accessibility: {
                    screenReaderSection: {
                        beforeChartFormat: '<{headingTagName}>{chartTitle}</{headingTagName}><div>{chartSubtitle}</div><div>{chartLongdesc}</div><div>{xAxisDescription}</div><div>{yAxisDescription}</div>'
                    }
                },
                tooltip: {
                    valueDecimals: 8
                },
                xAxis: {
                    type: 'datetime',
                    tickPixelInterval: 15,
                    labels: {
                        format: '{value: %d/%m/%y %H:%M:%S}',
                    },
                }, yAxis: [{
                    title: {
                        text: chart.metric,
                        style: {
                            fontSize: 18,
                            fontFamily: 'Seven Segment',
                            textTransform: false,
                        }
                    },
                    plotLines: [{
                        color: '#ff3300',
                        width: 2,
                        value: chart.max
                    }],
                    min: chart.viewMin,
                    max: chart.viewMax
                }],
                series: [{
                    name: "True Values", type: "spline", data: baseValues, color: "#1aa3ff"
                },
                    {
                        name: "Validated Values", type: "spline", data: validation, color: "#ff8c1a"
                    },
                    {
                        name: "predicted Values", type: "spline", data: futureSet, color: "#3caef1"
                    }
                ],
                credits: {
                    enabled: false,
                }
            }
        })

        // setCharts2(modifiedCharts)
        // @ts-ignore
        charts2.current = modifiedCharts
    }

    const deviceCharts1 = charts1.current.map((chartOptions,index)=>{
        let rand = Math.floor(Math.random() * 1000000) + 1
        return <Chart key={index + rand} chartOptions={chartOptions}/>
    })

    const deviceCharts2 = charts2.current.map((chartOptions,index)=>{
        let rand = Math.floor(Math.random() * 1000000) + 1
        return <Chart key={index + rand} chartOptions={chartOptions}/>
    })

    console.log(render)

    return(
        <Container className={'prediction'}>
            <Row className="header">
                <Col className="logo" md={1}>
                    <img src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'} />
                </Col>
                <Col className={'oncoTitle'}>
                    <h2>
                        AI Emission Predictor
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <div className={"predictionTitle"}>
                        <p>Morpheus {render ? "2000-1234" : "2000-1237"}</p>
                    </div>
                    <div className={"predCharts"}>
                        {render ? deviceCharts1 : deviceCharts2}
                    </div>
                </Col>
                <Col>
                    <PredStats
                        stats={render ? stats1 : stats2}
                    />
                </Col>
            </Row>
        </Container>
    )

}

/*
        setInterval(()=>{
            const clinics = ["2000-1234","2000-1237", "2000-1322"]
            const m20001234 =["QC: Required",
                "Enabled Energies: 50,60",
                "Installed x-ray tubes: Wide",
                "Total Logs: 48",
                "Total Emissions: 17"]
            const m20001237 =["QC: Required",
                "Enabled Energies: 50,60,70,80,90,100",
                "Installed x-ray tubes: Wide",
                "Total Logs: 5",
                "Total Emissions: 0"]
            const m20001322 =["QC: Complete",
                "Enabled Energies: 50,60,70",
                "Installed x-ray tubes: Narrow",
                "Total Logs: 11",
                "Total Emissions: 4"]
            let clinicName = clinics[Math.floor(Math.random()*clinics.length)];
            let message: string;
            if(clinicName ==="2000-1234"){
                message= m20001234[Math.floor(Math.random()*m20001234.length)]

            }
            else if(clinicName ==="2000-1237"){
                message= m20001237[Math.floor(Math.random()*m20001237.length)]

            }
            else if(clinicName ==="2000-1322"){
                message= m20001322[Math.floor(Math.random()*m20001322.length)]

            }

            // @ts-ignore
            msgArray.current.push({name: clinicName, message: message})

            toggleDevice(clinicName)
        },6000)

 */
