import React, {useState, useEffect, useContext, createContext} from "react";
import {API, Auth} from 'aws-amplify';
import LoginMainView from "./view_components/LoginMainView";
import {actionCreators} from '../../state_management/actions/authActions'
import {AuthContext} from "../../contexts/AuthContext";
//import formValidation from "../../utils/formValidation"
import './login.css'

interface ILoginForm{

    username: string,
    password: string,
    verificationCode : string
}

interface ILoginContext {

    signIn: ()=> void,
    resetPassword: ()=> void,
    confirmNewPassword: ()=> void,
    loginForm: ILoginForm,
    onChange: ( e: React.ChangeEvent<HTMLInputElement>) => void,
}

const formInputState = {
    username: '',
    password: '',
    verificationCode : ''
};

const initLoginContext = {

    signIn: ()=> {},
    resetPassword: ()=> {},
    confirmNewPassword: ()=> {},
    loginForm: formInputState,
    onChange: ( e:  React.ChangeEvent<HTMLInputElement>) => {},
}

export const LoginContext = createContext<ILoginContext>(initLoginContext);


// Todo: change logic login split context global and local with useform and error
export default function LoginContainer() {

    //:TODO send emails from an SES verified identity in production

    // Todo: change to reducer
    const [loginForm, setLoginForm] = useState(formInputState)
    const {authState, dispatch , signOut} = useContext(AuthContext);

    //use callback or use memo
    function onChange(e: React.FormEvent<HTMLInputElement>) {
        if(e.currentTarget){
            setLoginForm({...loginForm, [e.currentTarget.id]: e.currentTarget.value});
        }
    }

    async function signedIn(){

        try{
            dispatch(actionCreators.signedIn(true, '', null))
            const user = await Auth.currentAuthenticatedUser();
            localStorage['authentication'] = true
            localStorage['userName'] = user.username
            dispatch(actionCreators.signedIn(false, '', {
                userName: user.username,
                email: user.attributes.email,
                company: user.attributes['custom:company']
            }))
        }
        // why err any
        catch(err : any){
            dispatch(actionCreators.signedIn(false, err.message, null))
        }
    }

    async function signIn() {

        //let formValidation = isFormValid(['username','password'])

        //if (formValidation.valid) {
            dispatch(actionCreators.signIn(true, ''))
            try {
                await Auth.signIn(loginForm.username, loginForm.password);
                await signedIn();
            } catch (err) {
                dispatch(actionCreators.signIn(false,  'Username or password incorrect'))
            }
/*
        else {
            dispatch(actionCreators.signIn(false, formValidation.error))
        }

 */
    }

    async function resetPassword() {

        //let formValidation = isFormValid(['username'])

        //if (formValidation.valid) {
            dispatch(actionCreators.resetPassword(true, ''))
            try {
                let data = await Auth.forgotPassword(loginForm.username)
                dispatch(actionCreators.newPassword(false, ''))
            } catch (err : any) {
                dispatch(actionCreators.resetPassword(false, err.message))
            }
        }
        /*
        else{
            dispatch(actionCreators.resetPassword(false, formValidation.error))
        }

         */


    async function confirmNewPassword() {



        //let formValidation = isFormValid(['username','password','verificationCode'])

        //if (formValidation.valid) {
            dispatch(actionCreators.newPassword(true, ''))
            try {
                let data = await Auth.forgotPasswordSubmit(loginForm.username, loginForm.verificationCode, loginForm.password)
                dispatch(actionCreators.signIn(false, ""))
            } catch (err : any) {
                dispatch(actionCreators.newPassword(false, err.message))
            }
        /*
       }
       /*      else{
           dispatch(actionCreators.newPassword(false, formValidation.error))
       }

        */
    }

    /*
    function isFormValid(formFields) {

        let formCheckResult = {valid: true, error: {}}
        for (const field of formFields) {
            let checkFieldResult = formValidation(loginForm[field], formDictionaryTypes[field])

            if (!checkFieldResult.valid) {
                formCheckResult.error = {...formCheckResult.error, [field]: checkFieldResult.errorDescription}
                formCheckResult.valid = false;
            }
        }
        return formCheckResult;
    }

     */

    let loginControlled = {
        signIn: signIn,
        resetPassword: resetPassword,
        confirmNewPassword: confirmNewPassword,
        loginForm: loginForm,
        onChange: onChange,
    }

    return (
        <LoginContext.Provider value={loginControlled}>
            <LoginMainView/>
        </LoginContext.Provider>
    )
}

