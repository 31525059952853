import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {IDevice} from "../API";
import DeviceStats from "./DeviceStats";
import {devicesFakeData} from "../fakeDATA"
import {devicesDATA} from "../fakeDATA"
export default function DevicesTabs(props:any){

    const tabComponents = devicesDATA[0].devices.items.map((device:IDevice,index:number)=>{
        const deviceInitData = devicesFakeData.find((deviceInfo)=> deviceInfo.id === device.id )
        return(
            <Tab key={index} eventKey={device.serialNumber} title={
                device.serialNumber}>
                <DeviceStats
                    key={index}
                    deviceInitData={deviceInitData}
                    upTime={props.upTime}
                    setUpTime={props.setUpTime}
                    render={props.render}
                />
            </Tab>
        )
    })

    return(
        <Tabs
            className="mt-2 mb-3 deviceTabsTitle"
            activeKey={props.activeDevice}
        >
            {tabComponents}
        </Tabs>
    )
}