import React, {useContext} from 'react';
import {ActiveContext} from "../device/DeviceContainer";

import {
    Chart as ChartJS, ArcElement, Tooltip, Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
    Filler,
    RadialLinearScale,
} from 'chart.js';
import {Pie, Bar, Line, Doughnut, PolarArea} from 'react-chartjs-2';

import ChartDataLabels from 'chartjs-plugin-datalabels'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import * as Icon from 'react-bootstrap-icons';
import './DeviceSummary.css'
import CountUp from 'react-countup';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    RadialLinearScale,
    Filler,
    ChartDataLabels,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    PointElement,
    LineElement,
);




export const dataPolar = {
    labels: ['Red', 'Blue', 'Yellow'],
    datasets: [
        {
            label: '# of Votes',
            data: [10, 15, 20],
            backgroundColor: [
                'rgba(255, 99, 132, 0.5)',
                'rgba(54, 162, 235, 0.5)',
                'rgba(255, 206, 86, 0.5)',
            ],
            borderWidth: 1,
        },
    ],
};

export const horizontalBar1 = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    scales: {
        y: {
            ticks: {
                font: {
                    size: 10
                }
            } as const,
            grid: {
                display: false
            },
        },
        x: {
            ticks: {
                font: {
                    size: 10
                }
            } as const,
            grid: {
                display: false
            },
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                padding: 30
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            fontColor: "rgba(255, 99, 132, 0.5)",
            padding: {
                bottom: 30,
            },
            font: {
                size: 12
            },
            text: 'Total Consoles By Universe',
        },
        datalabels: {
            display: false
        }
    },
};

export const horizontalBarOptions = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    scales: {
        y: {
            ticks: {
                font: {
                    size: 10
                }
            } as const,
            grid: {
                display: false
            },
        },
        x: {
            ticks: {
                font: {
                    size: 10
                }
            } as const,
            grid: {
                display: false
            },
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                padding: 30
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            padding: {
                bottom: 30,
            },
            font: {
                size: 12
            },
            text: 'Total Consoles By Universe',
        },
        datalabels: {
            display: false
        }
    },
};

export const horizontalBarOptions1 = {
    maintainAspectRatio: false,
    indexAxis: 'y' as const,
    elements: {
        bar: {
            borderWidth: 2,
        },
    },
    scales: {
        y: {
            ticks: {
                color: "#99ddff",

                font: {
                    size: 16,
                    family: 'Seven Segment'


                }
            } as const,
            grid: {
                display: false
            },
        },
        x: {
            ticks: {
                color: "#99ddff",
                font: {
                    size: 16,
                    family: 'Seven Segment'

                }
            } as const,
            grid: {
                display: false
            },
        },
    },
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                padding: 30,
                color: "#99ddff",
                font: {
                    size: 14,
                    weight: 'bold' as const,
                    family: 'Seven Segment'

                },
            }

        },
        title: {
            align: 'start' as const,
            display: true,
            color: "#99ddff",
            padding: {
                bottom: 30,
            },
            font: {
                size: 20,
                family: 'Seven Segment'

            },
            text: 'Device Messages by Type',

        },
        datalabels: {
            display: false
        }
    },
};


const lineChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        y: {
            ticks: {
                font: {
                    size: 10,
                    family: 'Seven Segment'

                }
            } as const,
            grid: {
                display: false
            },
        },
        x: {
            ticks: {
                font: {
                    size: 8,
                    family: 'Seven Segment'

                }
            } as const,
            grid: {
                display: false
            },
        },
    },
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                padding: 30
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            padding: {
                bottom: 30,
            },
            font: {
                size: 12,
                family: 'Seven Segment'

            },
            text: 'Total Consoles By Month',
        },
        datalabels: {
            display: false
        }
    },

};

const sum = 144;

const sum1 = 28236;

const totalConsoles = 175;

const pieChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {

            position: 'bottom' as const,
            labels: {
                padding: 15,
                color: "#99ddff",
                font:{
                    size: 14,
                    weight: 'bold' as const,
                    family: 'Seven Segment'

                }
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            padding: {
                bottom: 25,
            },
            color: "#99ddff",
            font: {
                size: 20,
                family: 'Seven Segment'

            },
            text: 'Logs By Type',
        },
        datalabels: {
            formatter: (value: any, ctx: any) => {
                let percentage = (value * 100 / sum).toFixed(0) + "%";
                return percentage;
            },
            labels: {
                title: {
                    color: "#000000",

                    font: {
                        size: 14,
                        weight: 'bold' as const,
                        family: 'Seven Segment'

                    }
                }
            },

        }
    },
};


const doughnutChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            position: 'bottom' as const,
            labels: {
                padding: 23
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            padding: {
                bottom: 25,
            },
            font: {
                size: 16,
                family: 'Seven Segment'

            },
            text: 'Consoles By Type',
        },
        datalabels: {
            formatter: (value: any, ctx: any) => {
                let percentage = (value * 100 / totalConsoles).toFixed(0) + "%";
                return percentage;
            },
            labels: {
                title: {
                    font: {
                        size: 10,
                        weight: 'bold' as const,
                        family: 'Seven Segment'

                    }
                }
            },

        }
    },
};

export const barOptions = {
    responsive: true,
    scales: {
        y: {
            ticks: {
                color: '#99ddff',

                font: {
                    size: 16,
                    family: 'Seven Segment'

                }
            } as const,
            grid: {
                display: false
            },
        },
        x: {
            ticks: {
                color: '#99ddff',

                font: {
                    size: 16,
                    family: 'Seven Segment'

                }
            } as const,
            grid: {
                display: false
            },
        },
    },
    plugins: {

        legend: {
            position: 'bottom' as const,
            labels: {
                color: '#99ddff',

                padding: 5,
                font:{
                    size: 14,
                    weight: 'bold' as const,
                    family: 'Seven Segment'



}
            },
        },
        title: {
            align: 'start' as const,
            display: true,
            color: '#99ddff',

            padding: {
                bottom: 30,
            },
            font: {
                size: 20,
                family: 'Seven Segment'

            },
            text: 'Total Logs By Month',
        },
        datalabels: {
            display: false
        }
    },
};


const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July',
    'August', 'September'
];

export const barData20001234 = {
    labels,
    datasets: [
        {
            label: 'Logs',
            data: [15, 100, 400, 567, 988, 1333, 1555, 2058, 2555],
            backgroundColor: '#99ddff',
        },
    ],
};

export const barData20001237 = {
    labels,
    datasets: [
        {
            label: 'Logs',
            data: [15, 100, 407, 700, 1310, 1423, 2355, 2588, 3077],
            backgroundColor: '#99ddff',
        },
    ],
};



const faultsLabel = [
    'fl29', 'fl16', 'fl32',
    'fl25', 'fl11', 'fl20',
    'fl30', 'fl23', 'fl28',
    'fl19', 'fl26'

];


export const pieChartData20001234 = {
    labels: ['System', 'Service'
        ],
    datasets: [
        {
            data: [66, 78],
            backgroundColor: [
                '#ffefcc',
                '#99ddff',
            ],
            borderColor: [
                '#808000',
                '#006680',

            ],
            borderWidth: 1,
        },
    ],
};

export const pieChartData20001237 = {
    labels: ['System', 'Service'
    ],
    datasets: [
        {
            data: [70, 74],
            backgroundColor: [
                '#ffefcc',
                '#99ddff',
                '#ffefcc',
            ],
            borderColor: [
                '#196666',
                '#808000',
                '#808000',

            ],
            borderWidth: 1,
        },
    ],
};


export const horizontalBarData20001234 = {
    labels: faultsLabel,
    datasets: [
        {
            label: 'Device Messages',
            data: [12, 9, 8, 8, 6, 5, 3, 3, 2, 1, 1],
            borderColor: '#99ddff',
            backgroundColor: '#99ddff',
            barThickness: 6
        },
    ],
};

export const horizontalBarData20001237 = {
    labels: faultsLabel,
    datasets: [
        {
            label: 'Device Messages',
            data: [15, 10, 8, 7, 6, 5, 4, 3, 3, 1, 1],
            borderColor: '#99ddff',
            backgroundColor: '#99ddff',
            barThickness: 6
        },
    ],
};



export default function DeviceSummaryContainer() {

    const active = useContext(ActiveContext);


    return (
        <Stack gap={4}>
            <Row>

                <Col xs={12} sm={4}>
                    <div className={'rightChart'}>
                        <Bar
                            height={window.innerHeight*0.35}
                            options={horizontalBarOptions1}
                            data={
                            active === '2000-1234'?
                                horizontalBarData20001234:
                                horizontalBarData20001237
                        }/>
                    </div>
                </Col>
                <Col xs={12} sm={3}>
                    <div className={'pieChart'}>
                        <Pie
                            height={window.innerHeight*0.37}
                            options={pieChartOptions}
                            data={
                                active === '2000-1234'?
                                    pieChartData20001234:
                                    pieChartData20001237
                        }/>
                    </div>
                </Col>
                <Col xs={12} sm={5}>
                    <div className={'barChart'}>
                        <Bar height={window.innerHeight*0.25}
                             options={barOptions}
                             data={
                             active === '2000-1234'?
                                 barData20001234:
                                 barData20001237
                        } />
                    </div>
                </Col>
            </Row>

        </Stack>
    )
}

/*


 */


