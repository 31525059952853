import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from "../../components/layout/Header";
import HeatChart from "./view-components/HeatChart"
import "./heatmap.css"
import React, {useEffect, useRef, useState} from "react";
import categories from './categories.json'
import data from "./data.json"
import HeatStats from "./view-components/HeatStats";

export default function HeatMap(){

    const [currentData,setCurrentData] = useState<any>(data[0])
    const [device, setDevice] = useState(true);
    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: "heatmap",
            backgroundColor: '#171718',
            marginTop: window.innerHeight * 0.01,
            marginBottom: window.innerHeight * 0.2,
            plotBorderWidth: 0,
            borderWidth: 0,
            height: window.innerHeight * 0.7
        },
        credits: {
            enabled: false
        },
        exporting:{
            enabled:false
        },
        title: {
            text: undefined
        },

        xAxis: {
            categories: categories,
            labels: {
                style: {
                    fontFamily: 'Seven Segment',
                    fontSize: 17,
                }
            }
        },

        yAxis: {
            categories: categories,
            title: null,
            labels: {
                style: {
                    fontFamily: 'Seven Segment',
                    fontSize: 17
                }
            }
        },

        colorAxis: {
            min: -1,
            max: 1,
            minColor: "#ffffff",
            maxColor: "#0073e6",
            // stops: severityHexColors.map((hex, i, arr) => [i / arr.length, hex]),
            reversed: false
        },

        legend: {
            align: "right",
            layout: "vertical",
            margin: 10,
            verticalAlign: "middle",
            y: 25,
            symbolHeight: 280,
            backgroundColor: '#171718',

        },

        plotOptions: {
            series: {
                style: {
                    fontSize: 15
                }
            }
        },
        series: [
            {
                name: "Correlation",
                borderWidth: 1,
                data: [...data[0]["heatMap"]],
                dataLabels: {
                    enabled: true,
                    color: "#000000",
                    allowOverlap: true
                }
            }
        ]
    })

    const activeDevice = useRef<boolean>(true)
    const heatmap = useRef(null)

    let dataValue: number[][]

    useEffect(()=>{
        setInterval(()=>{
            activeDevice.current = !activeDevice.current
            if(activeDevice.current){
                setCurrentData(()=> data[0])
            }else{
                setCurrentData(()=> data[1])
            }
            setDevice((prevState) => !prevState)
        },10000)

    },[])

    useEffect(()=>{
        if(device)
            dataValue = [...data[0]["heatMap"]]
        else
            dataValue = [...data[1]["heatMap"]]
        setChartOptions(prevState => {
            return{
                ...prevState,
                series: {
                    ...prevState["series"],
                    data: dataValue
                }
            }
        })
    },[device])

    return(
        <Container className={'heatmap'}>
            <Row className="header">
                <Col className="logo" md={1}>
                    <img src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'} />
                </Col>
                <Col className={'oncoTitle'}>
                    <h2>
                        AI Signal Correlation
                    </h2>
                </Col>
            </Row>

            <Row>
                <Col md={8}>
                    <div className={"heatMapTitle"}>
                        <p>Morpheus {currentData["name"]}</p>
                    </div>
                    <HeatChart chartOptions={chartOptions} innerRef={heatmap}/>
                </Col>
                <Col>
                    <HeatStats
                        stats={currentData["stats"]}
                    />
                </Col>
            </Row>
        </Container>
    )
}
