import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import * as Icon from "react-bootstrap-icons";
import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {AuthContext} from "../../../contexts/AuthContext";
import {actionCreators} from '../../../state_management/actions/authActions'
import {LoginContext} from "../LoginContainer";


export default function NewPasswordForm(){

    const {dispatch, authState} = useContext(AuthContext);
    const {loginForm, onChange ,signIn, confirmNewPassword} = useContext(LoginContext);
    let error = authState.error;


    return(
        <>
            <Form noValidate validated={error !== ''} >
                <div className={'title'}>Reset your password</div>
                <Form.Label className={'formLabel'}>Verification code has been sent to your email now.</Form.Label>
                <Form.Group controlId="verificationCode">
                    <Form.Label>Verification code</Form.Label>
                    <Form.Control
                        value={loginForm.verificationCode}
                        type="text"
                        required
                        placeholder="Verification code"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                        value={loginForm.password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
                {authState.loading ?
                    <Row>
                        <Col md={{span: 4, offset: 4}}>
                            <Roller color="#70db70"  className={'spinnerSubmit'}/>
                        </Col>
                    </Row>
                    :
                    <Button variant="outline-success" onClick={confirmNewPassword}>
                        Submit<Icon.ShieldCheck size={20}/>
                    </Button>
                }
            </Form>
            <Form.Label className={'changeStateMessageLabel'}>No Account?
                <span className={'changeStateMessage'}
                      onClick={() => {dispatch(actionCreators.signIn(false, ""))}}>
                        Back to Sign In
                </span>
            </Form.Label>
            {error === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error}
                    </Col>
                </Row>
            }
        </>
    )
}



