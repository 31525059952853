import React, {useContext} from "react";
import SignInForm from "./SignInForm";
import ResetPasswordForm from "./ResetPasswordForm";
import NewPasswordForm from "./NewPasswordForm";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import {AuthContext} from "../../../contexts/AuthContext";
import '../login.css'


export default function LoginMainView() {

    const {authState} = useContext(AuthContext);

    let loginMainView;

    switch (authState.state) {
        case "signIn":
            loginMainView = <SignInForm/>
            break;
        case "resetPassword":
            loginMainView = <ResetPasswordForm/>
            break;
        case "newPassword":
            loginMainView = <NewPasswordForm/>
            break;
        default:
            loginMainView = <SignInForm/>
            break;
    }

    return (
        <Col md={{span: 4, offset: 4}} className={"loginPage"}>
             <div className={'logoImg'}>
                <Image src={process.env.PUBLIC_URL + '/Empyrean-Logo.png'}/>
             </div>
            <div className={'loginForm form-group'}>
                {loginMainView}
            </div>
        </Col>
    )
}
