import React, {useContext} from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {Roller} from "react-spinners-css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import * as Icon from "react-bootstrap-icons";
import {AuthContext} from "../../../contexts/AuthContext";
import {LoginContext} from "../LoginContainer";

import {actionCreators} from '../../../state_management/actions/authActions'


export default function SignInForm() {

    const {dispatch, authState} = useContext(AuthContext);
    const {loginForm, onChange ,signIn} = useContext(LoginContext);

    let error = authState.error;

    return (
        <>
            <Form noValidate validated={error !== ""} >
                <div className={'title'}>Sign in to your account</div>
                <Form.Group controlId="username">
                    <Form.Label className={'formLabel'}>Username</Form.Label>
                    <Form.Control
                        value={loginForm.username}
                        placeholder="username"
                        type="text"
                        maxLength={30}
                        required
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="password">
                    <Form.Label className={'formLabel'}>Password</Form.Label>
                    <Form.Control
                        value={loginForm.password}
                        type="password"
                        required
                        placeholder="Password"
                        onChange={onChange}/>
                    <Form.Control.Feedback type="invalid">
                        {error}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Label className={'formLabel '}>
                    Forgot your password?
                </Form.Label>
                <span className={'changeStateMessage'} onClick={() => {
                    dispatch(actionCreators.resetPassword(false, ""))
                }}>Reset Password</span>
                {authState.loading ?
                    <div>
                    <Button variant="outline-info" size="sm" className={'loginBtn'} disabled>
                        Sign In...
                    </Button>
                    </div>
                    :
                    <div>
                        <Button variant="outline-info" size="sm" className={'loginBtn'} onClick={signIn}>
                            Sign In
                        </Button>
                    </div>
                }
            </Form>
            {error === '' ?
                <></>:
                <Row>
                    <Col  className={'loginError'}>
                        {error}
                    </Col>
                </Row>
            }
        </>

    )
}
