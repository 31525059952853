import React, {useState, useEffect, useRef, useContext} from 'react'
import {ActiveContext} from "../device/DeviceContainer";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import {API, graphqlOperation, Storage} from 'aws-amplify';
import * as subscriptions from '../../graphql/subscriptions';

import {createDevice, updateDevice} from '../../graphql/mutations';
import {useParams} from "react-router-dom";

import GaugeChart from 'react-gauge-chart';

import "./deviceMonitoring.css"

//reuse global interface

interface IDeviceElectricity {

    id: string
    timestamp: string
    controlBoardMode: number
    emissionCurrent: number
    gridVoltage: number
    xCoilVoltage: number
    xCoilCurrent: number
    yCoilVoltage: number
    yCoilCurrent: number
    focusVoltage: number
    focusCurrent: number
    threePointThreeVSupply: number
    fiveVSupply: number
    twelveVSupply: number
}


interface IDeviceWater {

    id: string
    timestamp: string
    waterPressure: number
    waterFlowRate: number
}

interface IDeviceWater {

    id: string
    timestamp: string
    waterPressure: number
    waterFlowRate: number
}

interface IDeviceTemperature {

    id: string
    timestamp: string
    heatSinkTemperature: number
    peltierTemperature: number
    cabinetTemperature: number
    coilBoardTemperature: number
    waterTemperature: number
}

export default function DeviceMonitoringContainer(): JSX.Element {

    const active = useContext(ActiveContext);


    const [deviceElectricity, setDeviceElectricity] = useState<IDeviceElectricity>({

        id: '111',
        timestamp: '2022-06-30 10:49:04.565',
        controlBoardMode: 55,
        emissionCurrent: 1.2,
        gridVoltage: 40,
        xCoilVoltage: 0.3,
        xCoilCurrent: 40,
        yCoilVoltage: 0.3,
        yCoilCurrent: 40,
        focusVoltage: 4,
        focusCurrent: 0.2,
        threePointThreeVSupply: 3,
        fiveVSupply: 4,
        twelveVSupply: 10
    })

    const [deviceWater, setDeviceWater] = useState<IDeviceWater>({

        id: '111',
        timestamp: '2022-06-30 10:49:04.565',
        waterPressure: 4,
        waterFlowRate: 4

    })

    const [deviceTemperature, setDeviceTemperature] = useState<IDeviceTemperature>({

        id: '111',
        timestamp: '2022-06-30 10:49:04.565',
        heatSinkTemperature: 25,
        peltierTemperature: 25,
        cabinetTemperature: 26,
        coilBoardTemperature: 26,
        waterTemperature: 25

    })

    debugger

    useEffect(() => {


        const interval = setInterval(() => {
                // @ts-ignore
            setDeviceElectricity({
                id: '111',
                timestamp: '2022-06-30 10:49:04.565',
                controlBoardMode: 55,
                emissionCurrent: Number(parseFloat(String(Math.random() * (0.5))).toFixed(1)),
                gridVoltage: Number(parseFloat(String(Math.random() * (45 - 44) + 44)).toFixed(1)),
                xCoilVoltage: 0.3,
                xCoilCurrent: 40,
                yCoilVoltage: 0.3,
                yCoilCurrent: 40,
                focusVoltage: Number(parseFloat(String(Math.random() * (0.5) + 4)).toFixed(1)),
                focusCurrent: Number(parseFloat(String(Math.random() * (0.5))).toFixed(1)),
                threePointThreeVSupply: Number(parseFloat(String(Math.random() * (0.4 - 0.3)+0.3)).toFixed(1)),
                fiveVSupply: Number(parseFloat(String(Math.random() * (3.2 - 3) + 3)).toFixed(1)),
                twelveVSupply: Number(parseFloat(String(Math.random() * (10 - 8) + 8)).toFixed(1))
            })

            setDeviceWater({
                id: '111',
                timestamp: '2022-06-30 10:49:04.565',
                waterPressure: Number(parseFloat(String(Math.random() * (0.5) + 4)).toFixed(1)),
                waterFlowRate: Number(parseFloat(String(Math.random() * (0.5) + 4)).toFixed(1))

            })

            setDeviceTemperature({
                id: '111',
                timestamp: '2022-06-30 10:49:04.565',
                heatSinkTemperature: Number(parseFloat(String(Math.random() * (0.5) + 25)).toFixed(1)),
                peltierTemperature: Number(parseFloat(String(Math.random() * (0.5) + 25)).toFixed(1)),
                cabinetTemperature: Number(parseFloat(String(Math.random() * (0.5) + 25)).toFixed(1)),
                coilBoardTemperature: Number(parseFloat(String(Math.random() * (0.5) + 25)).toFixed(1)),
                waterTemperature: Number(parseFloat(String(Math.random() * (0.5) + 25)).toFixed(1)),


            })
            }, 1000);



            //return () => clearInterval(interval);







/*
        const subscription = API.graphql(graphqlOperation(subscriptions.onE))
            // fix type observerable
            // @ts-ignore
            .subscribe({
                next: ({provider, value}: any) => {

                    if(value.data.onE.deviceID.substring(4) === active.substring(5)){

                        setDeviceElectricity({
                            id: '111',
                            timestamp: '2022-06-30 10:49:04.565',
                            controlBoardMode: value.data.onE.controlBoardMode,
                            emissionCurrent: value.data.onE.emissionCurrent,
                            gridVoltage: value.data.onE.gridVoltage,
                            xCoilVoltage: value.data.onE.xCoilVoltage,
                            xCoilCurrent: value.data.onE.xCoilCurrent,
                            yCoilVoltage: value.data.onE.yCoilVoltage,
                            yCoilCurrent: value.data.onE.yCoilCurrent,
                            focusVoltage: value.data.onE.focusVoltage,
                            focusCurrent: value.data.onE.focusCurrent,
                            threePointThreeVSupply: value.data.onE.threePointThreeVSupply,
                            fiveVSupply: value.data.onE.fiveVSupply,
                            twelveVSupply: value.data.onE?.twelveVSupply
                        })

                    }
                },
                error: (error: Error) => console.warn(error)
            });


        const subscriptionWater = API.graphql(graphqlOperation(subscriptions.onWater))
            // fix type observerable
            // @ts-ignore
            .subscribe({
                next: ({provider, value}: any) => {
                    if(value.data.onWater.deviceID.substring(4) === active.substring(5)) {

                        setDeviceWater({
                            id: '111',
                            timestamp: '2022-06-30 10:49:04.565',
                            waterPressure: value.data.onWater.waterPressure,
                            waterFlowRate: value.data.onWater.waterFlowRate

                        })
                    }
                },
                error: (error: Error) => console.warn(error)
            });

        const subscriptionTemperature = API.graphql(graphqlOperation(subscriptions.onTemperature))
            // fix type observerable
            // @ts-ignore
            .subscribe({
                next: ({provider, value}: any) => {
                    if(value.data.onTemperature.deviceID.substring(4) === active.substring(5)) {

                        setDeviceTemperature({
                            id: '111',
                            timestamp: '2022-06-30 10:49:04.565',
                            heatSinkTemperature: value.data.onTemperature.heatSinkTemperature,
                            peltierTemperature: value.data.onTemperature.peltierTemperature,
                            cabinetTemperature: value.data.onTemperature.cabinetTemperature,
                            coilBoardTemperature: value.data.onTemperature.coilBoardTemperature,
                            waterTemperature: value.data.onTemperature.waterTemperature


                        })
                    }
                },
                error: (error: Error) => console.warn(error)
            });





 */



    }, [])



    const chartStyle = {
        height: 115,
    }


    // @ts-ignore
    // @ts-ignore
    return (
        <>
            <Row className={'gaugeRow deviceFont'}>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> Emission Current</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                percent={deviceElectricity.emissionCurrent*0.5}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => (Number(value)*0.02).toFixed(1) + " [MA]"}
                                style={chartStyle}
                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> Focus Coil Current</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                percent={deviceElectricity.focusCurrent / 3}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => (Number(value)*0.03).toFixed(1) + " [MA]"}
                                style={chartStyle}
                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6>Focus Coil Voltage</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                percent={deviceElectricity.focusVoltage / 18}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => (Number(value)*0.18).toFixed(1) + " [V]"}
                                style={chartStyle}
                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6>Grid V</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#5BE12C', '#F5CD19', '#EA4228']}
                                percent={deviceElectricity.gridVoltage/200}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => Number(value)*2 + " [V]"}
                                style={chartStyle}
                    />
                </Col>

            </Row>
            <Row className={'gaugeRow deviceFont'}>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6>Heatsink Temperature</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#ffff4d', '#F5CD19', '#EA4228']}
                                percent={deviceTemperature.heatSinkTemperature/100}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => Number(value)+ " °C"}
                                style={chartStyle}
                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6>Cabinet Temperature</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#ffff4d', '#F5CD19', '#EA4228']}
                                percent={deviceTemperature.cabinetTemperature/ 100}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                formatTextValue={(value) => Number(value)  + " °C"}
                                style={chartStyle}
                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> Water Pressure</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#cce6ff', '#1a8cff', '#004280']}
                                percent={deviceWater.waterPressure/18}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                style={chartStyle}
                                formatTextValue={(value) => (Number(value)*0.18).toFixed(1) + " PSI"}

                    />
                </Col>
                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> Water Flow Rate</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={false}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#cce6ff', '#1a8cff', '#004280']}
                                percent={deviceWater.waterFlowRate/10.9}
                                textColor={"#ffffff"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                style={chartStyle}
                                formatTextValue={(value) => (Number(value)*0.109).toFixed(1) + " LPM"}

                    />
                </Col>

            </Row>
        </>
    );
}


/*

                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> WP</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={true}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#cce6ff', '#1a8cff', '#004280']}
                                percent={0.33}
                                textColor={"#666666"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                style={chartStyle}
                    />
                </Col>

                                <Col xs={3} className={'gaugeCol'}>
                    <Row>
                        <h6> WFR</h6>
                    </Row>
                    <GaugeChart id="gauge-chart6"
                                animate={true}
                                animDelay={0}
                                nrOfLevels={30}
                                colors={['#cce6ff', '#1a8cff', '#004280']}
                                percent={0.35}
                                textColor={"#666666"}
                                needleColor={"#b3b3b3"}
                                needleBaseColor={"#a6a6a6"}
                                style={chartStyle}
                    />
                </Col>



 */







