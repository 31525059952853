import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountUp from "react-countup";

export default function PredStats(props:any){


    return(
        <Container className={'predStats'}>
            <Row>
                <Row>
                    <h3 className={'statsTitle'}>EC Prediction stats</h3>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[0]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MAX
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[1]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MIN
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[2]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                Median
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[3]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MEAN
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row>
                <h3 className={'statsTitle'}>AP Prediction stats</h3>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[4]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MAX
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[5]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MIN
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[6]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MEDIAN
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cardLayout'}>
                            <div className={'cardData'}>
                                <CountUp
                                    end={props.stats[7]}
                                    decimals={4}
                                    duration={2}
                                />
                            </div>
                            <div className={'cardDescription'}>
                                MEAN
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>
        </Container>
    )
}