import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';
import {API, graphqlOperation, Storage} from 'aws-amplify';

import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';

import Image from 'react-bootstrap/Image';

import {useParams} from "react-router-dom";

import * as Icon from 'react-bootstrap-icons';
import {Roller} from "react-spinners-css";


import {LinkContainer} from "react-router-bootstrap";


import Spinner from "react-bootstrap/Spinner";

//reuse global

interface IDevice {

    serialNumber: string
    location: string

}

export default function ProductInfo(props: IDevice) {

    return (
        <>
            <h5 className={'itemTitle'}>
                {props.serialNumber ? <TitleElement
                                                    serialNumber={props.serialNumber}/> :
                    ''}

            </h5>
            <Stack gap={2} className={'itemCardData'}>
                <div className={'cardDescriptionLayout'}>
                    <Icon.Laptop size={14}/>
                    <span className={'cardRowTitle'}> Installation Date: 20.1.2023
                    </span>
                </div>
                <div className={'cardDescriptionLayout'}>
                    <Icon.GeoAlt size={14}/>
                    <span className={'cardRowTitle'}> Location: {props.location}</span>
                </div>
            </Stack>
        </>
    )
}

function TitleElement(props : {serialNumber: string}) {

    return (
        <>
            <>Morpheus </>
            <span className={'deviceSerialNumber'}>{props.serialNumber} </span>
        </>
    )
}
