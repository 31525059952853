/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onMyMutation = /* GraphQL */ `
  subscription OnMyMutation($groups: String!) {
    onMyMutation(groups: $groups) {
      id
      name
      description
      groups
    }
  }
`;
export const onCreatedFun = /* GraphQL */ `
  subscription OnCreatedFun($groups: String!) {
    onCreatedFun(groups: $groups) {
      id
      name
      description
      groups
    }
  }
`;
export const onTodoMutation = /* GraphQL */ `
  subscription OnTodoMutation {
    onTodoMutation {
      id
      name
      description
      groups
    }
  }
`;
export const onE = /* GraphQL */ `
  subscription OnE {
    onE {
      id
      deviceID
      timestamp
      controlBoardMode
      emissionCurrent
      gridVoltage
      xCoilVoltage
      xCoilCurrent
      yCoilVoltage
      yCoilCurrent
      focusVoltage
      focusCurrent
      threePointThreeVSupply
      fiveVSupply
      twelveVSupply
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onWater = /* GraphQL */ `
  subscription OnWater {
    onWater {
      id
      deviceID
      timestamp
      waterPressure
      waterFlowRate
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onTemperature = /* GraphQL */ `
  subscription OnTemperature {
    onTemperature {
      id
      deviceID
      timestamp
      heatSinkTemperature
      peltierTemperature
      cabinetTemperature
      coilBoardTemperature
      waterTemperature
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onOS = /* GraphQL */ `
  subscription OnOS {
    onOS {
      id
      deviceID
      uptime
      CPUUsage
      usedMemory
      memoryUsage
      processesNumber
      networkReceived
      networkSent
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onLog = /* GraphQL */ `
  subscription OnLog {
    onLog {
      id
      deviceID
      RowID
      Message
      MessageType
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTodo = /* GraphQL */ `
  subscription OnCreateTodo {
    onCreateTodo {
      id
      name
      description
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTodo = /* GraphQL */ `
  subscription OnUpdateTodo {
    onUpdateTodo {
      id
      name
      description
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTodo = /* GraphQL */ `
  subscription OnDeleteTodo {
    onDeleteTodo {
      id
      name
      description
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateLogger = /* GraphQL */ `
  subscription OnCreateLogger {
    onCreateLogger {
      id
      deviceID
      RowID
      Message
      MessageType
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLogger = /* GraphQL */ `
  subscription OnUpdateLogger {
    onUpdateLogger {
      id
      deviceID
      RowID
      Message
      MessageType
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLogger = /* GraphQL */ `
  subscription OnDeleteLogger {
    onDeleteLogger {
      id
      deviceID
      RowID
      Message
      MessageType
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateService = /* GraphQL */ `
  subscription OnCreateService {
    onCreateService {
      id
      deviceID
      serviceID
      timeStamp
      message
      user
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateService = /* GraphQL */ `
  subscription OnUpdateService {
    onUpdateService {
      id
      deviceID
      serviceID
      timeStamp
      message
      user
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteService = /* GraphQL */ `
  subscription OnDeleteService {
    onDeleteService {
      id
      deviceID
      serviceID
      timeStamp
      message
      user
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePost = /* GraphQL */ `
  subscription OnCreatePost {
    onCreatePost {
      id
      title
      comments {
        items {
          id
          postID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePost = /* GraphQL */ `
  subscription OnUpdatePost {
    onUpdatePost {
      id
      title
      comments {
        items {
          id
          postID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePost = /* GraphQL */ `
  subscription OnDeletePost {
    onDeletePost {
      id
      title
      comments {
        items {
          id
          postID
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      postID
      content
      post {
        id
        title
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      postID
      content
      post {
        id
        title
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      postID
      content
      post {
        id
        title
        comments {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCompany = /* GraphQL */ `
  subscription OnCreateCompany {
    onCreateCompany {
      id
      name
      address
      lat
      lng
      clinics {
        items {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          username
          firstName
          lastName
          role
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      patients {
        items {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCompany = /* GraphQL */ `
  subscription OnUpdateCompany {
    onUpdateCompany {
      id
      name
      address
      lat
      lng
      clinics {
        items {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          username
          firstName
          lastName
          role
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      patients {
        items {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCompany = /* GraphQL */ `
  subscription OnDeleteCompany {
    onDeleteCompany {
      id
      name
      address
      lat
      lng
      clinics {
        items {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          companyID
          username
          firstName
          lastName
          role
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      patients {
        items {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateClinic = /* GraphQL */ `
  subscription OnCreateClinic {
    onCreateClinic {
      id
      companyID
      name
      address
      lat
      lng
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateClinic = /* GraphQL */ `
  subscription OnUpdateClinic {
    onUpdateClinic {
      id
      companyID
      name
      address
      lat
      lng
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteClinic = /* GraphQL */ `
  subscription OnDeleteClinic {
    onDeleteClinic {
      id
      companyID
      name
      address
      lat
      lng
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateProduct = /* GraphQL */ `
  subscription OnCreateProduct {
    onCreateProduct {
      id
      name
      description
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateProduct = /* GraphQL */ `
  subscription OnUpdateProduct {
    onUpdateProduct {
      id
      name
      description
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteProduct = /* GraphQL */ `
  subscription OnDeleteProduct {
    onDeleteProduct {
      id
      name
      description
      devices {
        items {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      serialNumber
      typeID
      clinicID
      type {
        id
        name
        description
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        name
        address
        lat
        lng
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          deviceID
          RowID
          Message
          MessageType
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      temperature {
        items {
          id
          deviceID
          timestamp
          heatSinkTemperature
          peltierTemperature
          cabinetTemperature
          coilBoardTemperature
          waterTemperature
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      water {
        items {
          id
          deviceID
          timestamp
          waterPressure
          waterFlowRate
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      electricity {
        items {
          id
          deviceID
          timestamp
          controlBoardMode
          emissionCurrent
          gridVoltage
          xCoilVoltage
          xCoilCurrent
          yCoilVoltage
          yCoilCurrent
          focusVoltage
          focusCurrent
          threePointThreeVSupply
          fiveVSupply
          twelveVSupply
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      service {
        items {
          id
          deviceID
          serviceID
          timeStamp
          message
          user
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      os {
        items {
          id
          deviceID
          uptime
          CPUUsage
          usedMemory
          memoryUsage
          processesNumber
          networkReceived
          networkSent
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      serialNumber
      typeID
      clinicID
      type {
        id
        name
        description
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        name
        address
        lat
        lng
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          deviceID
          RowID
          Message
          MessageType
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      temperature {
        items {
          id
          deviceID
          timestamp
          heatSinkTemperature
          peltierTemperature
          cabinetTemperature
          coilBoardTemperature
          waterTemperature
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      water {
        items {
          id
          deviceID
          timestamp
          waterPressure
          waterFlowRate
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      electricity {
        items {
          id
          deviceID
          timestamp
          controlBoardMode
          emissionCurrent
          gridVoltage
          xCoilVoltage
          xCoilCurrent
          yCoilVoltage
          yCoilCurrent
          focusVoltage
          focusCurrent
          threePointThreeVSupply
          fiveVSupply
          twelveVSupply
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      service {
        items {
          id
          deviceID
          serviceID
          timeStamp
          message
          user
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      os {
        items {
          id
          deviceID
          uptime
          CPUUsage
          usedMemory
          memoryUsage
          processesNumber
          networkReceived
          networkSent
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      serialNumber
      typeID
      clinicID
      type {
        id
        name
        description
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      currentClinic {
        id
        companyID
        name
        address
        lat
        lng
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        devices {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          deviceID
          RowID
          Message
          MessageType
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      temperature {
        items {
          id
          deviceID
          timestamp
          heatSinkTemperature
          peltierTemperature
          cabinetTemperature
          coilBoardTemperature
          waterTemperature
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      water {
        items {
          id
          deviceID
          timestamp
          waterPressure
          waterFlowRate
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      electricity {
        items {
          id
          deviceID
          timestamp
          controlBoardMode
          emissionCurrent
          gridVoltage
          xCoilVoltage
          xCoilCurrent
          yCoilVoltage
          yCoilCurrent
          focusVoltage
          focusCurrent
          threePointThreeVSupply
          fiveVSupply
          twelveVSupply
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      service {
        items {
          id
          deviceID
          serviceID
          timeStamp
          message
          user
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      os {
        items {
          id
          deviceID
          uptime
          CPUUsage
          usedMemory
          memoryUsage
          processesNumber
          networkReceived
          networkSent
          groups
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      companyID
      username
      firstName
      lastName
      role
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      companyID
      username
      firstName
      lastName
      role
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      companyID
      username
      firstName
      lastName
      role
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePatient = /* GraphQL */ `
  subscription OnCreatePatient {
    onCreatePatient {
      id
      companyID
      firstName
      lastName
      birthdate
      sex
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePatient = /* GraphQL */ `
  subscription OnUpdatePatient {
    onUpdatePatient {
      id
      companyID
      firstName
      lastName
      birthdate
      sex
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePatient = /* GraphQL */ `
  subscription OnDeletePatient {
    onDeletePatient {
      id
      companyID
      firstName
      lastName
      birthdate
      sex
      hasImage
      company {
        id
        name
        address
        lat
        lng
        clinics {
          nextToken
        }
        users {
          nextToken
        }
        patients {
          nextToken
        }
        createdAt
        updatedAt
      }
      studies {
        items {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStudy = /* GraphQL */ `
  subscription OnCreateStudy {
    onCreateStudy {
      id
      patientID
      deviceID
      timestamp
      description
      patient {
        id
        companyID
        firstName
        lastName
        birthdate
        sex
        hasImage
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        createdAt
        updatedAt
      }
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      series {
        items {
          id
          studyID
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStudy = /* GraphQL */ `
  subscription OnUpdateStudy {
    onUpdateStudy {
      id
      patientID
      deviceID
      timestamp
      description
      patient {
        id
        companyID
        firstName
        lastName
        birthdate
        sex
        hasImage
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        createdAt
        updatedAt
      }
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      series {
        items {
          id
          studyID
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStudy = /* GraphQL */ `
  subscription OnDeleteStudy {
    onDeleteStudy {
      id
      patientID
      deviceID
      timestamp
      description
      patient {
        id
        companyID
        firstName
        lastName
        birthdate
        sex
        hasImage
        company {
          id
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        createdAt
        updatedAt
      }
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      series {
        items {
          id
          studyID
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSeries = /* GraphQL */ `
  subscription OnCreateSeries {
    onCreateSeries {
      id
      studyID
      timestamp
      study {
        id
        patientID
        deviceID
        timestamp
        description
        patient {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        device {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        series {
          nextToken
        }
        createdAt
        updatedAt
      }
      volumes {
        items {
          id
          seriesID
          rows
          columns
          pixelRepresentation
          rescaleSlope
          rescaleIntercept
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      voxels {
        items {
          id
          seriesID
          sliceThickness
          pixelSpacingX
          pixelSpacingY
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSeries = /* GraphQL */ `
  subscription OnUpdateSeries {
    onUpdateSeries {
      id
      studyID
      timestamp
      study {
        id
        patientID
        deviceID
        timestamp
        description
        patient {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        device {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        series {
          nextToken
        }
        createdAt
        updatedAt
      }
      volumes {
        items {
          id
          seriesID
          rows
          columns
          pixelRepresentation
          rescaleSlope
          rescaleIntercept
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      voxels {
        items {
          id
          seriesID
          sliceThickness
          pixelSpacingX
          pixelSpacingY
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSeries = /* GraphQL */ `
  subscription OnDeleteSeries {
    onDeleteSeries {
      id
      studyID
      timestamp
      study {
        id
        patientID
        deviceID
        timestamp
        description
        patient {
          id
          companyID
          firstName
          lastName
          birthdate
          sex
          hasImage
          createdAt
          updatedAt
        }
        device {
          id
          serialNumber
          typeID
          clinicID
          createdAt
          updatedAt
        }
        series {
          nextToken
        }
        createdAt
        updatedAt
      }
      volumes {
        items {
          id
          seriesID
          rows
          columns
          pixelRepresentation
          rescaleSlope
          rescaleIntercept
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      voxels {
        items {
          id
          seriesID
          sliceThickness
          pixelSpacingX
          pixelSpacingY
          timestamp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVolume = /* GraphQL */ `
  subscription OnCreateVolume {
    onCreateVolume {
      id
      seriesID
      rows
      columns
      pixelRepresentation
      rescaleSlope
      rescaleIntercept
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVolume = /* GraphQL */ `
  subscription OnUpdateVolume {
    onUpdateVolume {
      id
      seriesID
      rows
      columns
      pixelRepresentation
      rescaleSlope
      rescaleIntercept
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVolume = /* GraphQL */ `
  subscription OnDeleteVolume {
    onDeleteVolume {
      id
      seriesID
      rows
      columns
      pixelRepresentation
      rescaleSlope
      rescaleIntercept
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVoxel = /* GraphQL */ `
  subscription OnCreateVoxel {
    onCreateVoxel {
      id
      seriesID
      sliceThickness
      pixelSpacingX
      pixelSpacingY
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVoxel = /* GraphQL */ `
  subscription OnUpdateVoxel {
    onUpdateVoxel {
      id
      seriesID
      sliceThickness
      pixelSpacingX
      pixelSpacingY
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVoxel = /* GraphQL */ `
  subscription OnDeleteVoxel {
    onDeleteVoxel {
      id
      seriesID
      sliceThickness
      pixelSpacingX
      pixelSpacingY
      timestamp
      series {
        id
        studyID
        timestamp
        study {
          id
          patientID
          deviceID
          timestamp
          description
          createdAt
          updatedAt
        }
        volumes {
          nextToken
        }
        voxels {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceTemperature = /* GraphQL */ `
  subscription OnCreateDeviceTemperature {
    onCreateDeviceTemperature {
      id
      deviceID
      timestamp
      heatSinkTemperature
      peltierTemperature
      cabinetTemperature
      coilBoardTemperature
      waterTemperature
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceTemperature = /* GraphQL */ `
  subscription OnUpdateDeviceTemperature {
    onUpdateDeviceTemperature {
      id
      deviceID
      timestamp
      heatSinkTemperature
      peltierTemperature
      cabinetTemperature
      coilBoardTemperature
      waterTemperature
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceTemperature = /* GraphQL */ `
  subscription OnDeleteDeviceTemperature {
    onDeleteDeviceTemperature {
      id
      deviceID
      timestamp
      heatSinkTemperature
      peltierTemperature
      cabinetTemperature
      coilBoardTemperature
      waterTemperature
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceOS = /* GraphQL */ `
  subscription OnCreateDeviceOS {
    onCreateDeviceOS {
      id
      deviceID
      uptime
      CPUUsage
      usedMemory
      memoryUsage
      processesNumber
      networkReceived
      networkSent
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceOS = /* GraphQL */ `
  subscription OnUpdateDeviceOS {
    onUpdateDeviceOS {
      id
      deviceID
      uptime
      CPUUsage
      usedMemory
      memoryUsage
      processesNumber
      networkReceived
      networkSent
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceOS = /* GraphQL */ `
  subscription OnDeleteDeviceOS {
    onDeleteDeviceOS {
      id
      deviceID
      uptime
      CPUUsage
      usedMemory
      memoryUsage
      processesNumber
      networkReceived
      networkSent
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceWater = /* GraphQL */ `
  subscription OnCreateDeviceWater {
    onCreateDeviceWater {
      id
      deviceID
      timestamp
      waterPressure
      waterFlowRate
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceWater = /* GraphQL */ `
  subscription OnUpdateDeviceWater {
    onUpdateDeviceWater {
      id
      deviceID
      timestamp
      waterPressure
      waterFlowRate
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceWater = /* GraphQL */ `
  subscription OnDeleteDeviceWater {
    onDeleteDeviceWater {
      id
      deviceID
      timestamp
      waterPressure
      waterFlowRate
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDeviceElectricity = /* GraphQL */ `
  subscription OnCreateDeviceElectricity {
    onCreateDeviceElectricity {
      id
      deviceID
      timestamp
      controlBoardMode
      emissionCurrent
      gridVoltage
      xCoilVoltage
      xCoilCurrent
      yCoilVoltage
      yCoilCurrent
      focusVoltage
      focusCurrent
      threePointThreeVSupply
      fiveVSupply
      twelveVSupply
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDeviceElectricity = /* GraphQL */ `
  subscription OnUpdateDeviceElectricity {
    onUpdateDeviceElectricity {
      id
      deviceID
      timestamp
      controlBoardMode
      emissionCurrent
      gridVoltage
      xCoilVoltage
      xCoilCurrent
      yCoilVoltage
      yCoilCurrent
      focusVoltage
      focusCurrent
      threePointThreeVSupply
      fiveVSupply
      twelveVSupply
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDeviceElectricity = /* GraphQL */ `
  subscription OnDeleteDeviceElectricity {
    onDeleteDeviceElectricity {
      id
      deviceID
      timestamp
      controlBoardMode
      emissionCurrent
      gridVoltage
      xCoilVoltage
      xCoilCurrent
      yCoilVoltage
      yCoilCurrent
      focusVoltage
      focusCurrent
      threePointThreeVSupply
      fiveVSupply
      twelveVSupply
      device {
        id
        serialNumber
        typeID
        clinicID
        type {
          id
          name
          description
          createdAt
          updatedAt
        }
        currentClinic {
          id
          companyID
          name
          address
          lat
          lng
          createdAt
          updatedAt
        }
        studies {
          nextToken
        }
        logs {
          nextToken
        }
        temperature {
          nextToken
        }
        water {
          nextToken
        }
        electricity {
          nextToken
        }
        service {
          nextToken
        }
        os {
          nextToken
        }
        createdAt
        updatedAt
      }
      groups
      createdAt
      updatedAt
    }
  }
`;
